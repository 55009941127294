import React, { useRef } from "react";
import { Box, Container, Typography } from "@mui/material";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Header from "../Components/Header";
import Aboutus from "../Components/Aboutus";
import Whyus from "../Components/Whyus";
import Footer from "../Components/Footer";
import FAQ from "../Components/FAQ";
import Contact from "../Components/Contact";
import Commitment from "../Components/Commitment";
import GiveBack from "../Components/GiveBack";
import Testimonials from "../Components/Testimonials";

export default function Home() {
  return (
    <Box>
      <ResponsiveAppBar />
      <Header 
      type={1}
      backgroundImage = '/images/commerical_hero.jpg'
      />
      <Aboutus />
      <Whyus />
      <Commitment />
      {/* <Box backgroundColor={'#EEF7F6'} sx={{
            paddingTop:'80px',
      paddingBottom:'80px'
    }}>
      </Box> */}
      <Testimonials />
      <FAQ />
      <Contact />
      <GiveBack />
      <Footer />
    </Box>
  );
}

const styles = {
  header: {},
};
