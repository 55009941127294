import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid2,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { featuredImages } from "../../Data/FeaturedServices";
import theme from "../../theme";
import { type } from "@testing-library/user-event/dist/type";
import { handleGetQuote } from "../../helpers/helperFunctions";

const FeaturedServicesModal = ({ open,activeContainer, handleClose }) => {
  const [activeSection, setActiveSection] = useState(null);
  const sectionRefs = useRef([]);
  const [height, setHeight] = useState(0);
  const dialogContentRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  useEffect(() => {
    setTimeout(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                const index = sectionRefs.current.indexOf(entry.target);
                setActiveSection(index); 
              }
            });
          };
      
          const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: "0px 0px -20% 0px",
            threshold: 0.5,
          });
      
          sectionRefs.current.forEach((section) => {
            if (section) observer.observe(section);
          });
          if (open && dialogContentRef.current) {
            // Get the height of the modal content
            setHeight(dialogContentRef.current.offsetHeight);
          }
      
          return () => {
            sectionRefs.current.forEach((section) => {
              if (section) observer.unobserve(section);
            });
          };
    }, 100);
  }, []);

  useEffect(() => {
    setTimeout(() => {
        if (activeContainer) {
            setActiveSection(activeContainer); 
            scrollToIndex(activeContainer);
        }
    }, 300);
  }, [activeContainer]);

  const renderFeaturedCard = (image, title, content, index) => {
    return (
      <Card
        ref={(el) => (sectionRefs.current[index] = el)}
        sx={{ width: "100%", borderRadius: "30px", marginBottom: "24px" }}
      >
        <CardMedia sx={{ height:isMobile?219: 256 }} image={image} title="green iguana" />
        <CardContent
          sx={{
            padding: "24px 28px",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            fontWeight={500}
            color="#0D0D12"
            lineHeight={"28px"}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            fontWeight={400}
            color="#666D80"
            lineHeight={"24px"}
          >
            {content}
          </Typography>
        </CardContent>
        <CardActions
          sx={{
            padding: "0px 24px",
          }}
        >
          <Button
            sx={{
              padding: "8px 20px",
              backgroundColor: "#00ADA0",
              borderRadius: "38px",
              marginBottom: "38px",
            }}
            onClick={handleGetQuote}
            id="get-quote-menu-item"
          >
            <Typography
              variant="body2"
              fontWeight={500}
              lineHeight={"28px"}
              color="#F8F8F8"
            >
              Get an estimate now
            </Typography>
          </Button>
        </CardActions>
      </Card>
    );
  };

  const scrollToIndex = (index) => {
    sectionRefs.current[index].scrollIntoView({ });
                        setActiveSection(index);
  }

  return (
    <div>
      {/* Modal Component */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg" // Set to large width
        fullWidth // Ensures modal takes up the full width allowed by maxWidth
      >
        <DialogTitle>
          <Typography
            variant="h5"
            fontWeight={500}
            lineHeight={"28px"}
            color="#000000"
            padding={"16px 0px"}
          >
            Featured Services
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            padding: "0px",
            borderTop: "2px solid #E6E6E6",
          }}
          ref={dialogContentRef}
        >
          <Grid2 container spacing={2} sx={{
            position: "relative",
          }}>
            {!isMobile ?
            <Grid2 size={{ xs: 12, sm: 12, md: 4 }}>
              <Box
                backgroundColor="#EEF7F6"
                sx={{
                  padding: "40px 33px",
                  // height: {height},
                  position: "sticky",
                  top: 0,
                }}
              >
                {featuredImages?.map((item, index) =>
                 {return (
                  <Box>
                    {index == 0 ? (
                      <Typography
                        variant="h5"
                        fontWeight={500}
                        lineHeight={"28px"}
                        color="#000000"
                        padding={"16px 0px"}
                      >
                        Commercial Services
                      </Typography>
                    ) : index == 5?
                    (
                      <Typography
                        variant="h5"
                        fontWeight={500}
                        lineHeight={"28px"}
                        color="#000000"
                        padding={"16px 0px"}
                      >
                        Residential Services
                      </Typography>
                    ):null}
                  {activeSection === index ? (
                    <Box
                      sx={{
                        padding: "12px 0px 0px 0px",
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          borderTop: "2px solid #00ADA0",
                          width: "50px",
                          marginRight: "15px",
                        }}
                      />
                      <Typography
                        variant="body1"
                        fontWeight={700}
                        color="#00262E"
                        lineHeight={"20px"}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        padding: "12px 0px",
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        marginTop: index == 5 ? '0px':"12px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        scrollToIndex(index);
                      }}
                    >
                      <Typography
                        variant="body1"
                        fontWeight={400}
                        color="#00262E"
                        lineHeight={"20px"}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  )}
                  </Box>
                  )}
                )}
              </Box>
            </Grid2>
            :
            null
}
            <Grid2 size={{ xs: 12, sm: 12, md: 8 }}>
              <Box
                sx={{
                  padding:isMobile?"20px 10px": "40px 33px",
                }}
              >
                {featuredImages.map((item, index) =>
                {                  
                  return (
                    renderFeaturedCard(
                      item.image,
                      item.title,
                      item.fullcontent,
                     index
                    )
                  )
                }
                )}
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FeaturedServicesModal;
