import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Grid2,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { useTheme } from "@emotion/react";
import TestimonialModal from "./TestimonialModal";
import testimonials from "../Data/Testimonials";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

const BackgroundBox = styled(Box)(({ theme }) => ({
  position: "absolute", // Use absolute positioning
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: `linear-gradient(135deg, #40C4AA, #FFFFFF 60%, #C2F2A7)`, // Diagonal linear gradient
  backgroundColor: "#FFFFFF", // White background
  overflow: "hidden",
  zIndex: 1, // Ensure it's behind the card
}));

const Circle = styled(Box)(({ size, color }) => ({
  position: "absolute",
  borderRadius: "50%",
  background: `radial-gradient(circle at 50% 50%, ${color} 10%, rgba(255, 255, 255, 0.1) 60%)`, // Decreased intensity in the center
  width: size,
  height: size,
  opacity: 0.8, // Adjust for transparency
}));

const renderTestimonialCard = (
  rating,
  content,
  name,
  firstLetter,
  handleOpen,
  index
) => {
  return (
    <Grid2
      size={{
        xs: 12,
        sm: 12,
        md: 4,
      }}
    >
      <ScrollAnimation
        animateIn="fadeInUp"
        delay={100 * (index + 1)}
        animateOnce
        duration={1.5}
      >
        <Card
          sx={{
            backgroundColor: "#FFFFFF",
            // boxShadow: 3, // Add some shadow for depth
            padding: "10px",
            borderRadius: "16px",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "space-between",
          }}
          onClick={() => {
            handleOpen(index);
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box component={"img"} src="/images/icons/star.png" />
              <Typography
                variant="h6"
                fontWeight={500}
                color="#191919"
                ml="8px"
              >
                {rating}
              </Typography>
            </Box>
            <Typography
              mt="12px"
              variant="h6"
              fontWeight={400}
              color="#191919"
              lineHeight={1.4}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 4,
                WebkitBoxOrient: "vertical",
                cursor: "pointer",
                // "&:hover": {
                //   WebkitLineClamp: "none",
                // },
              }}
            >
              {content}
            </Typography>
            <Box
              mt="18px"
              sx={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* <Box component={"img"} src="/images/image.png" /> */}
              <Box
                sx={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  backgroundColor: "#40C4AA",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  fontWeight={500}
                  color="#FFFFFF"
                  sx={{
                    textTransform: "uppercase",
                    textAlign: "center",
                    lineHeight: "40px",
                  }}
                >
                  {firstLetter}
                </Typography>
              </Box>
              <Box ml="16px" sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body1" fontWeight={400} color="#191919">
                  {name}
                </Typography>
                {/* <Typography variant="body2" fontWeight={400} color="#191919">
                New York, NY
              </Typography> */}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </ScrollAnimation>
    </Grid2>
  );
};

const Testimonials = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);
  const [selectedTestimonial, setSelectedTestimonial] = React.useState(null);
  const handleOpen = (index) => {
    setOpen(true);
    setSelectedTestimonial(testimonials[index]);
  };
  const handleClose = () => setOpen(false);
  return (
    <Box sx={{ position: "relative" }}>
      <BackgroundBox>
        {/* <Circle
          size="100%"
          color="#40C4AA"
          style={{ top: "-10%", left: "-30%" }}
        />
        <Circle
          size="80%"
          color="#40C4AA"
          style={{ bottom: "0%", left: "-10%" }}
        />
        <Circle
          size="100%"
          color="#C2F2A7"
          style={{ bottom: "-10%", right: "-40%" }}
        /> */}
      </BackgroundBox>

      {/* Card on top of circles */}
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
          paddingTop: "80px",
          paddingBottom: "80px",
        }}
      >
          <ScrollAnimation
        animateIn="fadeInUp"
        delay={0}
        animateOnce
        duration={1.5}
      >
        <Typography variant="h5" fontWeight={400} color="#00262E">
          See What Our Customers Are Saying
        </Typography>
        <Typography variant="h6" fontWeight={400} color="#00262E" mt={"8px"}>
          Here’s what some of our customers say about our services.
        </Typography>
        </ScrollAnimation>
        <Box mt="50px">
          <Grid2 container spacing={2}>
            {isMobile ? (
              testimonials.map((item, index) => 
                renderTestimonialCard(
                  item.rating,
                  item.review,
                  item.name,
                  item.firstLetter,
                  handleOpen,
                  index
                )
              )
            ) : (
              <>
                {testimonials.map((item, index) => 
                  renderTestimonialCard(
                    item.rating,
                    item.review,
                    item.name,
                    item.firstLetter,
                    handleOpen,
                    index
                  )
                  )}
              </>
            )}
          </Grid2>
          <Grid2 container spacing={2} mt={"60px"}>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 4,
              }}
              mt={"12px"}
            >
                        <ScrollAnimation animateIn="fadeInUp" delay={100} animateOnce duration={1.5}>
              <Typography variant="h6" color="#0F172A" fontWeight={400}>
                Trusted By Our Beloved Customers at
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Box component={"img"} src="/images/icons/star.png" />
                <Typography
                  variant="h6"
                  color="#00262E"
                  fontWeight={400}
                  ml="5px"
                >
                  4.6
                </Typography>
                <Box
                  sx={{
                    height: "4px",
                    width: "4px",
                    backgroundColor: "#00262E",
                    borderRadius: "50%",
                  }}
                  ml={"12px"}
                  mr={"12px"}
                />
                <Typography variant="h6" color="#00262E" fontWeight={400}>
                  24 Reviews
                </Typography>
              </Box>
              </ScrollAnimation>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 4,
              }}
              mt={"12px"}
            >
                                      <ScrollAnimation animateIn="fadeInUp" delay={100} animateOnce duration={1.5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
              <Box
                component={"img"}
                src="/images/testimonials1.png"
                sx={{
                  height: "80px",
                }}
              />
               <Box
                component={"img"}
                src="/images/testimonials2.png"
                sx={{
                  height: "40px",
                }}
              />
              </Box>
              </ScrollAnimation>
            </Grid2>
            {/* <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 4,
              }}
              mt={"12px"}
              sx={{
                display:'flex',
                justifyContent:'center',
              }}
            >
              <Box
                component={"img"}
                src="/images/illustrations/testimonial.png"
                sx={{
                  width: "252px",
                  height:'200px',
                  position:'absolute',
                  bottom:0
                }}
              />
            </Grid2> */}
          </Grid2>
        </Box>
      </Container>
      {open && (
        <TestimonialModal
          open={open}
          handleClose={handleClose}
          selectedTestimonial={selectedTestimonial}
        />
      )}
    </Box>
  );
};

export default Testimonials;
