import { Box, Container, Grid2, Link, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { initiateCall, initiateMail } from "../helpers/helperFunctions";

export default function Footer() {
  const navigate = useNavigate();
  const handleNavigation = (title) => {
    window.scrollTo(0, 0);
    if (title === "Home") {
      navigate("/");
    } else if (title === "About") {
      navigate("/about");
    } else if (title === "Commercial") {
      navigate("/services/commercial");
    } else if (title === "Residential") {
      navigate("/services/residential");
    }
  };

  const renderFooterLink = (title) => {
    return (
      <Link
        onClick={() => {
          handleNavigation(title);
        }}
        sx={{
          cursor: "pointer",
          textDecoration: "none",
        }}
      >
        <Typography variant="body2" mt={"12px"} color="#FFFFFF">
          {title}
        </Typography>
      </Link>
    );
  };
  return (
    <Box
      sx={{
        backgroundColor: "#00262E",
      }}
      pt={"56px"}
      pb={"56px"}
    >
      <Box
      // sx={{
      //     backgroundImage:"url('/images/pattern/footer.png')",
      //     backgroundSize:'cover',
      // }}
      >
        <Container>
          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 8,
              }}
            >
              <Box
                component={"img"}
                src="/images/footerLogo.png"
                sx={{
                  height: "90px",
                  backgroundColor: "#FFFFFF",
                  padding: "6px",
                  borderRadius: "50%",
                }}
              />
              {/* <Typography
              variant="body2"
              sx={{
                position: "absolute",
                bottom: 0,
              }}
            >
              © Copyright wonderland cleaners
            </Typography> */}
            </Grid2>
            <Grid2
              size={{
                xs: 6,
                sm: 6,
                md: 2,
              }}
            >
              <Typography
                variant="body2"
                mb={"8px"}
                color="#FFFFFF"
                fontWeight={"bold"}
              >
                Quicklinks
              </Typography>
              {renderFooterLink("Home")}
              {/* {renderFooterLink("Services")} */}
              {renderFooterLink("About")}
              {/* {renderFooterLink("Contact")} */}
            </Grid2>
            <Grid2
              size={{
                xs: 6,
                sm: 6,
                md: 2,
              }}
            >
              <Typography
                variant="body2"
                mb={"8px"}
                color="#FFFFFF"
                fontWeight={"bold"}
              >
                Services
              </Typography>
              {renderFooterLink("Commercial")}
              {renderFooterLink("Residential")}
              {/* {renderFooterLink("Home")} */}
            </Grid2>
          </Grid2>
          <Grid2 container spacing={2} mt={6}>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 8,
              }}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body2" color="#FFFFFF">
                © Copyright - Wonderland Cleaning
              </Typography>
            </Grid2>
            <Grid2
              size={{
                xs: 6,
                sm: 6,
                md: 2,
              }}
            >
              <Typography
                variant="body2"
                color="#FFFFFF"
                onClick={() => {
                  window.open("/pdf/cookies-policy.pdf", "_blank");
                }}
                sx={{
                  cursor: "pointer",
                }}
              >
                Cookie Policy
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                onClick={() => {
                  initiateCall("+1 360-200-7191");
                }}
                sx={{
                  cursor: "pointer",
                }}
                mt={2}
              >
                +1 360-200-7191
              </Typography>
            </Grid2>
            <Grid2
              size={{
                xs: 6,
                sm: 6,
                md: 2,
              }}
            >
              <Typography
                variant="body2"
                color="#FFFFFF"
                onClick={() => {
                  window.open("/pdf/Privacy-Policy.pdf", "_blank");
                }}
                sx={{
                  cursor: "pointer",
                }}
              >
                Privacy Policy
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                onClick={() => {
                  initiateMail("office@wonderlandclean.com");
                }}
                sx={{
                  cursor: "pointer",
                }}
                mt={2}
              >
                office@wonderlandclean.com
              </Typography>
            </Grid2>
          </Grid2>
        </Container>
      </Box>
    </Box>
  );
}
