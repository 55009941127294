const testimonials = [
    {
        id: 1,
        name: "Sande V",
        firstLetter: "S",
        review:
            "I'm 65 yrs old and a recently retired RN. Cleaning is a Big Deal to me. We've used Tyler's services happily for over a year. Tyler has a sense of responsibility that I've seldom seen. This company is hard working and dedicated to high standards like I've rarely seen in any profession. You won't be disappointed if you decide to use them.",
        rating: 5,
    },
    {
        id: 2,
        name: "K Lodge",
        firstLetter: "K",
        review: "Tyler did a phenomenal job with our carpet and house cleaning. He went above and beyond to make sure we were fully satisfied with their service and communicated consistently with me throughout the process. Wonderland cleaning took the stress out of our moving process to ensure we left our house cleaner than when we moved in.",
        rating: 5,
    },
    {
        id: 3,
        name: "Beth N.",
        firstLetter: "B",
        review: "Wonderland Cleaning stepped in to help me 8 years ago, every other week, and they have been a great team! I love coming home to a beautifully clean house, they do excellent work. They are careful and detailed, the house is always sparkling. They are easy to communicate with about anything that needs to be worked out, such as choice of cleaning products or particular cleaning instructions. They are always reliable. They are respectful of the house - I have the utmost trust of them when they are working and I am not there, never any problems. I look forward to every other week on my cleaning day, what a treat to come home from work to a house that feels so well cared for!",
        rating: 5,
    },
    {
        id: 4,
        name: "Mars",
        firstLetter: "M",
        review: "Everyone I dealt with at the company was helpful and professional. We only had a few days to turn over the keys and needed help quickly. Other companies told us they couldn't get a team for us in time. Tyler worked with us and was able to get 3 awesome maids to agree to come in on their day off, on a Sunday. The team listened to everything we needed them to do and did a great job. I would recommend and use Wonderland Cleaning again.",
        rating: 5,
    },
    {
        id: 5,
        name: "Carmen Miller",
        firstLetter: "C",
        review: "Hi there! We lived on base & when the guys came they let me escort them on. Even when their truck broke down they kept me updated & still came to do the job. I really appreciate the work ethic! The carpets got clean over the whole house & stairs. It was great work!",
        rating: 5,
    },
    {
        id: 6,
        name: "Samantha Allison",
        firstLetter: "S",
        review: "Tyler and his team are fantastic!  They helped us with a family emergency when we needed a last minute clean due to illness. They scrambled to find resources and did a terrific job!  They provided additional cleaning after and continued to deliver great service and results. We can’t thank them enough!",
        rating: 5,
    },

]

export default testimonials