import { Box, Button, Container, Grid2, Typography } from '@mui/material'
import React from 'react'
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import { useNavigate } from 'react-router-dom';

export default function Aboutus(props) {
    const navigate = useNavigate();
  return (
    <Box
    sx={{
        backgroundColor:'#EEF7F6',
        paddingTop:'80px',
        paddingBottom:'80px'
    }}
    ref={props?.aboutSectionRef}
    >
        <Container>
        <ScrollAnimation animateIn="fadeInUp" delay={0} animateOnce duration={1.5}>
            <Grid2 container spacing={2}>
                <Grid2 size={{ xs:12,sm:12,md:2 }}>
                    <Typography variant='h5' fontWeight={400} color='#00262E'>
                        About Us
                    </Typography>
                </Grid2>
                <Grid2 size={{
                    xs:12,
                    sm:12,
                    md:10
                }}>
                    <Typography variant='h6' fontWeight={400} color='#191919'>
                    At Wonderland Cleaning, we've been committed to making Kitsap County shine for over 13 years. Trusted by leading names like Islandwood, Pleasant Beach Village, and Silverdale Center for Surgery, our reputation for reliability, affordability, and professionalism speaks for itself. As a proud local business, we go beyond just cleaning—we give back, contributing a portion of our revenue to support local charities. Our commitment to our community is matched only by our dedication to our clients.
                    </Typography>
                </Grid2>
            </Grid2>
            </ScrollAnimation>
         <ScrollAnimation animateIn="fadeInUp" delay={100} animateOnce duration={1.5}>
            <Box
            sx={{
                display:'flex',
                justifyContent:'center',
                marginTop:'40px'
            }}
            >
            <Button
                onClick={()=>{
    window.scrollTo(0,0)
                    navigate("/about");
                }}
                sx={{
                  backgroundColor: "transparent",
                  border:'1px solid #000000',
                  padding: "14px 20px",
                  borderRadius: "80px",
                  color: "#000000",
                  margin:'0px 20px 0px 40px',
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  ml={"8px"}
                  color="#000000"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Know More
                </Typography>
              </Button>
            </Box>
            </ScrollAnimation>
        {/* <ScrollAnimation animateIn="fadeInUp" delay={100} animateOnce duration={1.5}>
            <Box
            component={'img'}
            src='/images/aboutUsVideo.png'
            sx={{
                borderRadius:'16px',
                width:'100%'
            }}
            mt={'48px'}
            />
            </ScrollAnimation> */}
            </Container>
    </Box>
  )
}
