import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './Screens/Home';
import About from './Screens/About';
import CommercialServices from './Screens/CommercialServices';
import ResidentialServices from './Screens/ResidentialServices';
import GetQuote from './Screens/GetQuote';
import Test from './Screens/Test';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/services/commercial" element={<CommercialServices />} />
      <Route path="/services/residential" element={<ResidentialServices />} />
      <Route path="/getQuote" element={<GetQuote />} />
      <Route path="/test" element={<Test />} />
    </Routes>
  );
}

export default App;
