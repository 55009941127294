import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  styled,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';

// Custom connector to style the lines between the steps
const CustomConnector = styled(StepConnector)(({ theme,activeStep,completedSteps }) => { 
  console.log("bhanu",completedSteps.includes(activeStep-1) ? 'red' : theme.palette.grey[400])
  return({
  '&.MuiStepConnector-root': {
    left: 'calc(-50% + 16px)',  // Center the connector line
    right: 'calc(50% + 16px)',  // Center the connector line
    top:'40%',
  },
  '& .MuiStepConnector-line': {
    borderTopWidth: 4,  // Set the width of the line to 2px
    borderRadius: 1,
    borderColor: completedSteps.includes(activeStep-1) ? 'red' : theme.palette.grey[400],
    },
})});


// Custom step icon styling
const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 30,
  height: 30,
  borderRadius: '50%',
  border: `2px solid ${theme.palette.grey[400]}`, // Default grey border for inactive steps
  ...(ownerState.active && {
    border: `2px solid #00ADA0`,  // Red border for active step
  }),
  ...(ownerState.completed && {
    backgroundColor: '#00ADA0',  // Red background for completed step
    border: 'none',          // No border for completed step
  }),
}));

// Custom step icon component
function CustomStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <CheckIcon style={{ color: 'white', height:18 }}/>  // White check icon for completed steps
      ) : (
        <Typography>
          {props?.index}
        </Typography>
        // <CircleIcon style={{ color: active ? '#00ADA0' : 'grey' }} />  // Grey or red circle for incomplete steps
      )}
    </CustomStepIconRoot>
  );
}

const steps = ['', '', '', ''];

const CustomStepper = () => {
  const [activeStep, setActiveStep] = useState(1); // Step 1 is active (index starts at 0)
  // Function to get completed steps
  const completedSteps = [];
  for (let i = 0; i < activeStep; i++) {
    completedSteps.push(i);
  }
  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
      <Stepper
        activeStep={activeStep}
        connector={<CustomConnector activeStep={activeStep} completedSteps={completedSteps} />}
        alternativeLabel
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
            sx={{
              width:'50px'
            }}
              StepIconComponent={(props) => (
                <CustomStepIcon
                  {...props}
                  completed={activeStep > index}
                  active={activeStep === index}
                  index={index+1}
                />
              )}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <div>
            <p>All steps completed!</p>
            <button onClick={handleReset}>Reset</button>
          </div>
        ) : (
          <div>
            <p>Step {activeStep + 1} in progress</p>
            <button onClick={handleNext}>
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomStepper;
