import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Menu,
  MenuItem,
  useMediaQuery,
  Box,
  Container,
  Divider,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";
import {
  ArrowForward,
  Call,
  KeyboardArrowDown,
  Mail,
  Phone,
  PhoneEnabledRounded,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { handleConversionEvent, handleGetQuote, initiateCall, initiateMail } from "../helpers/helperFunctions";

function ResponsiveAppBar(props) {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [desktopAnchorEl, setDesktopAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("Home");
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const routes = {
    Home: "/",
    About: "/about",
    Services: "services",
    Contact: "contact",
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleServicesMenuOpen = (event) => {
    setDesktopAnchorEl(event.currentTarget);
  };

  const handleServicesMenuClose = () => {
    setDesktopAnchorEl(null);
  };

  const renderNavItem = (title) => {
    return (
      <Link
        onClick={() => {
          handleNavigation(title);
        }}
        sx={{
          cursor: "pointer",
          textDecoration:'none'

        }}
      >
        <Typography
          variant="body2"
          fontWeight={700}
          mr={"40px"}
          sx={{
            color: location.pathname === routes[title] ? "#00ADA0" : "#000000",
          }}
        >
          {title}
        </Typography>
      </Link>
    );
  };

  const handleNavigation = (title) => {
    if(title!="Contact"){
      setActiveSection(title);
    }
    handleMenuClose();
    window.scrollTo(0,0)
    if (title === "Home") {
      navigate("/");
    } else if (title === "About") {
      navigate("/about");
    } else if (title === "Commercial") {
      navigate("/services/commercial");
    } else if (title === "Residential") {
      navigate("/services/residential");
    }
    else if (title === "Contact") {
      if(isMobile){
        initiateCall('+1 360-200-7191');
      }
      else{
        handleOpen();
      }
      // handleConversionEvent('contact');
    }
  };

  return (
    <Box>
          <AppBar
      position="fixed"
      color="transparent"
      sx={{
        // background: "linear-gradient(45deg, #DAFFFF33,#DAFFFF33)",
        backgroundColor: "#DAFFFF20",
        backdropFilter: "blur(40px)", // For the frosted glass effect
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)", // Glossy shadow
        borderBottom: "0.5px solid rgba(255, 255, 255, 0.3)",
      }}
    >
      <Container>
        <Toolbar>
          {/* Logo */}
          {/* <IconButton edge="start" color="inherit" aria-label="logo"> */}
          <img
            src={"/images/logoNew.png"}
            alt="Logo"
            style={{
              height: isMobile ? "52px" : "80px",
              paddingTop: 8,
              paddingBottom: 8,
            }}
          />
          {/* </IconButton> */}
          {/* Space between Logo and Menu Options */}
          <div style={{ flexGrow: 1 }} />{" "}
          {/* This will push the buttons to the right */}
          {isMobile ? (
            // Mobile View: Show Menu Icon
            <>
             <IconButton
                    sx={{
                      backgroundColor:'#FFF',
                      margin:'0px 10px 0px 0px',
                      padding:'8px',
                      color:'#00ADA0'
                    }}

                    onClick={()=>{
                      handleNavigation("Contact")
                    }}
                    >
                      <Call/>
                    </IconButton>
                    <Button
                    onClick={handleGetQuote}
                id="get-quote-menu-item"
                    sx={{
                      backgroundColor: "#00ADA0",
                      borderColor: "#00ADA0",
                      padding: "12px 14px",
                      borderRadius: "80px",
                      margin:'0px 20px 0px 0px',

                    }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={500}
                      color="#FFFFFF"
                    >
                      Estimate
                    </Typography>
                  </Button>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  sx={{
                    width: "300px",
                  }}
                  onClick={() => {
                    handleNavigation("Home");
                  }}
                >
                  {renderNavItem("Home")}
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={() => {
                    handleNavigation("About");
                  }}
                >
                  {renderNavItem("About")}
                </MenuItem>
                <Divider />

                <MenuItem
                  sx={{
                    padding: 0,
                  }}
                  disableRipple
                >
                  <Accordion
                    sx={{
                      boxShadow: 0,
                      width: "100%",
                    }}
                  >
                    <AccordionSummary expandIcon={<KeyboardArrowDown />}>
                      <Typography
                        variant="body2"
                        fontWeight={700}
                        mr={"40px"}
                        sx={{
                          color:
                            location.pathname.split("/")[1] ===
                            routes["Services"]
                              ? "#00ADA0"
                              : "#000000",
                        }}
                      >
                        Services
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        paddingTop: 0,
                      }}
                    >
                      <Button
                        fontFamily={600}
                        sx={{
                          padding: "20px 100% 20px 0px",
                          fontWeight: 400,
                          fontSize: "13px",
                          color: "#000",
                        }}
                        disableRipple
                        onClick={() => {
                          handleNavigation("Commercial");
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={700}
                          mr={"40px"}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                            color:
                              location.pathname.split("/")[2] === "commercial"
                                ? "#00ADA0"
                                : "#000000",
                          }}
                        >
                          Commercial Cleaning
                        </Typography>
                      </Button>
                      <Divider />
                      <Button
                        fontFamily={600}
                        sx={{
                          padding: "20px 100% 0px 0px",
                          fontWeight: 400,
                          fontSize: "13px",
                          color: "#000",
                        }}
                        disableRipple
                        onClick={() => {
                          handleNavigation("Residential");
                        }}
                      >
                        <Typography
                          variant="body2"
                          fontWeight={700}
                          mr={"40px"}
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            cursor: "pointer",
                            color:
                              location.pathname.split("/")[2] === "residential"
                                ? "#00ADA0"
                                : "#000000",
                          }}
                        >
                          Residential Cleaning
                        </Typography>
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                </MenuItem>            
              </Menu>
            </>
          ) : (
            // Desktop View: Show Buttons
            <>
              {renderNavItem("Home")}
              {renderNavItem("About")}
              <div>
                <Typography
                  variant="body2"
                  fontWeight={700}
                  // mr={"40px"}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    cursor: "pointer",
                    color:
                      location.pathname.split("/")[1] === routes["Services"]
                        ? "#00ADA0"
                        : "#000000",
                  }}
                  onClick={handleServicesMenuOpen}
                >
                  Services
                  <KeyboardArrowDown sx={{ marginLeft: 0.5 }} />{" "}
                </Typography>
                <Menu
                  anchorEl={desktopAnchorEl}
                  open={Boolean(desktopAnchorEl)}
                  onClose={handleServicesMenuClose} // Close the dropdown menu on close
                >
                  <MenuItem
                    onClick={() => {
                      handleServicesMenuClose();
                      handleNavigation("Commercial");
                    }}
                    sx={{ padding: "20px 25%",width:'300px' }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={700}
                      mr={"40px"}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                        color:
                          location.pathname.split("/")[2] === "commercial"
                            ? "#00ADA0"
                            : "#000000",
                      }}
                    >
                      Commercial Cleaning
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleServicesMenuClose();
                      handleNavigation("Residential");
                    }}
                    sx={{ padding: "20px 25%",width:'300px' }}
                  >
                    <Typography
                      variant="body2"
                      fontWeight={700}
                      mr={"40px"}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        cursor: "pointer",
                        color:
                          location.pathname.split("/")[2] === "residential"
                            ? "#00ADA0"
                            : "#000000",
                      }}
                    >
                      Residential Cleaning
                    </Typography>
                  </MenuItem>
                </Menu>
              </div>
                    {/* <IconButton
                    sx={{
                      backgroundColor:'#FFF',
                      margin:'0px 20px 0px 40px',
                      padding:'12px',
                      color:'#00ADA0'
                    }}

                    onClick={()=>{
                      handleNavigation("Contact")
                    }}
                    >
                      <Call/>
                    </IconButton> */}
                      <Button
                onClick={()=>{
                  handleNavigation("Contact")
                }}
                id="contact-menu-item"
                sx={{
                  backgroundColor: "#FEFEFE",
                  borderColor: "#ECEFF3",
                  padding: "14px 32px",
                  borderRadius: "80px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  color:"#00ADA0",
                  margin:'0px 20px 0px 40px',
                }}
              >
                <Call
                sx={{
                  transform: "rotate(90deg)",
                  width: "20px",
                }}
                />
                <Typography
                  variant="body2"
                  fontWeight={500}
                  ml={"8px"}
                  color="#00ADA0"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Contact
                </Typography>
                {/* <Box component={"img"} src="/images/icons/rightArrowLong.png" /> */}
              </Button>
              {/* {renderNavItem("Contact")} */}
              <Button
                onClick={handleGetQuote}
                sx={{
                  backgroundColor: "#FEFEFE",
                  borderColor: "#ECEFF3",
                  padding: "14px 32px",
                  borderRadius: "80px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
                id="get-quote-menu-item"
              >
                <Typography
                  variant="body2"
                  fontWeight={500}
                  mr={"16px"}
                  color="#00ADA0"
                  sx={{
                    textTransform: "capitalize",
                  }}
                >
                  Estimate
                </Typography>
                <Box component={"img"} src="/images/icons/rightArrowLong.png" />
              </Button>
              {/* <div>
                <Button
                  color="inherit"
                  onClick={handleMenuOpen} // Open the dropdown menu on click
                >
                  Services
                  <KeyboardArrowDown sx={{ marginLeft: 0.5 }} />{" "}
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose} // Close the dropdown menu on close
                >
                  <MenuItem onClick={handleMenuClose}>Service 1</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Service 2</MenuItem>
                  <MenuItem onClick={handleMenuClose}>Service 3</MenuItem>
                </Menu>
              </div> */}
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
            <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Typography
            variant="h5"
            fontWeight={500}
            lineHeight={"28px"}
            color="#000000"
            padding={"16px 0px 0px 0px"}
          >
            Contact Us
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            padding: "0px",
          }}
        >
            <Box
            sx={{
              backgroundColor:'#EEF7F6',
              borderRadius:'10px',
              padding:'21px 26px',
              margin:'30px 31px',
              display:'flex',
              alignItems:'center',
              gap:'25px',
              cursor:'pointer'
            }}
            onClick={() => {
              initiateCall('+1 360-200-7191');
            }}
            >
              <Phone
              sx={{
                height:'24px',
                width:'24px',
                transform:'rotate(90deg)'
              }}
              />
                <Typography
                  variant="body1"
                  fontWeight={400}
                  lineHeight={"27px"}
                  color="#000000"
                >
                  +1 360-200-7191
                </Typography>
            </Box>
            <Box
            sx={{
              backgroundColor:'#EEF7F6',
              borderRadius:'10px',
              padding:'21px 26px',
              margin:'30px 31px',
              display:'flex',
              alignItems:'center',
              gap:'25px',
              cursor:'pointer'
            }}
            onClick={() => {
              initiateMail('office@wonderlandclean.com');
            }}
            >
              <Mail
              sx={{
                height:'24px',
                width:'24px',
              }}
              />
                <Typography
                  variant="body1"
                  fontWeight={400}
                  lineHeight={"27px"}
                  color="#000000"
                >
                  office@wonderlandclean.com
                </Typography>
            </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default ResponsiveAppBar;
