import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid2,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

export default function Commitment() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const renderCard = (
    icon,
    title,
    content,
    pattern,
    showIllustration = false
  ) => {
    return (
      <Box
        sx={{
          height: "100%",
        }}
      >
        {/* {showIllustration && !isMobile && (
          <Box
            component={"img"}
            src="/images/illustrations/commitmentIllustration.png"
            sx={{
              width: "100%",
              marginTop: "-100%",
            }}
          />
        )} */}
        <Card
          sx={{
            display: "flex",
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            padding: isMobile ? "20px 30px 30px 30px" : "56px 30px 30px 30px",
            backgroundImage: `url(${pattern})`,
            backgroundPosition: "top right",
            backgroundRepeat: "no-repeat",
            // marginTop: showIllustration ? (isMobile ? "20px" : "0px") : "20px",
            marginTop:'20px',
            height: "70%",
          }}
        >
          <CardContent>
            <Box component={"img"} src={icon} mb={isMobile ? "20px" : "56px"} />
            <Typography variant="h6" fontWeight={400} color="#191919">
              {title}
            </Typography>
            <Typography variant="body2" color="#00262E" mt={"8px"}>
              {content}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: "#EEF7F6",
      }}
      pt={"80px"}
      pb={"80px"}
    >
      <Container>
      <ScrollAnimation animateIn="fadeInUp" delay={0} animateOnce duration={1.5}>
        <Typography variant="h5" color="#00262E" fontWeight={400}>
          Our Commitment to Excellence
        </Typography>
        <Typography variant="h6" mt={1} color="#00262E" fontWeight={400}>
          Experience the high standards that drive our exceptional cleaning
          results.
        </Typography>
        </ScrollAnimation>
        <Box mt={"64px"}>
          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
              sx={{
                display: "flex",
              }}
            >
              <ScrollAnimation animateIn="fadeInUp" delay={100} animateOnce duration={1.5}>
                {renderCard(
                  "/images/icons/eco.png",
                  "Eco-Friendly Cleaning Solutions",
                  "We use eco-friendly products and sustainable practices for a safe, healthy environment.",
                  "/images/pattern/ecoPattern.png"
                )}
              </ScrollAnimation>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
              sx={{
                display: "flex",
              }}
            >
              <ScrollAnimation animateIn="fadeInUp" delay={200} animateOnce duration={1.5}>
                {renderCard(
                  "/images/icons/trainedBadge.png",
                  "Trained and Trusted Professionals",
                  "Our vetted professionals provide exceptional cleaning & meticulous attention to detail.",
                  "/images/pattern/trainedPattern.png"
                )}
              </ScrollAnimation>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
              sx={{
                display: "flex",
              }}
            >
              <ScrollAnimation animateIn="fadeInUp" delay={300} animateOnce duration={1.5}>
                {renderCard(
                  "/images/icons/customizable.png",
                  "Customizable Cleaning Plans",
                  "Custom cleaning plans for one-time deep cleans or regular maintenance.",
                  "/images/pattern/customizationPattern.png"
                )}
              </ScrollAnimation>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
              sx={{
                display: "flex",
              }}
            >
              <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce duration={1.5}>
                {renderCard(
                  "/images/icons/tech.png",
                  "Advanced Cleaning Technology",
                  "Using cutting-edge cleaning tech and gear for top-notch results.",
                  "/images/pattern/techPattern.png",
                  true
                )}
              </ScrollAnimation>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}
