import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { handleGetQuote } from "../../helpers/helperFunctions";

const BackgroundContainer = styled(Box)(({ theme }) => ({
  position: "relative", // Allows for overlay positioning
  width: "100%",
  backgroundImage: 'url("/images/commercialBuilding.jpeg")', // Replace with your image URL
  backgroundSize: "cover", // Ensures the image covers the entire container
  backgroundPosition: "center", // Centers the background image
  backgroundRepeat: "no-repeat", // Prevents image repetition
  overflow: "hidden",
}));

const Overlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "#00000066", // Semi-transparent black overlay
}));

const Content = styled(Container)(({ theme }) => ({
  position: "relative", // Keeps content above the overlay
  zIndex: 1, // Ensures the content is above the overlay
  display: "flex",
  height: "100%", // Centers content vertically and horizontally
  padding: "80px 0px",
}));

const FullBackgroundWithOverlay = (props) => {
  return (
    <BackgroundContainer>
      <Overlay />
      <Content>
        <Container>
          {/* <Typography
            variant="h6"
            fontWeight={400}
            lineHeight={"28px"}
            color="#FFFFFF"
          >
            15% OFF
          </Typography> */}
          <Typography
            variant="h3"
            fontWeight={900}
            lineHeight={"80px"}
            color="#F8F8F8"
            mt={"16px"}
          >
            Book your cleaning today!
          </Typography>
          <Typography
            variant="body1"
            fontWeight={400}
            lineHeight={"21px"}
            color="#FFFFFF"
            mt={"8px"}
          >
           {props?.content}
          </Typography>
          <Box
            mt={10}
            sx={{
              display: "flex",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "16px 20px",
                borderRadius: "48px",
              }}
              onClick={handleGetQuote}
              id="get-quote-menu-item"
            >
              <Typography
                variant="body2"
                lineHeight={"18px"}
                fontWeight={500}
                textAlign={"center"}
                color="#00262E"
                sx={{
                    textTransform: "capitalize",
                  }}
              >
                Get an Estimate
              </Typography>
            </Button>
            {/* <Button
              sx={{
                backgroundColor: "#FFFFFF33",
                backdropFilter:'blur(14px)',
                padding: "16px 20px",
                borderRadius: "48px",
                marginLeft:'8px'
              }}
            >
              <Typography
                variant="body2"
                lineHeight={"18px"}
                fontWeight={500}
                textAlign={"center"}
                color="#FFFFFF"
                sx={{
                    textTransform: "capitalize",
                  }}
              >
                Contact Now
              </Typography>
            </Button> */}
          </Box>
        </Container>
      </Content>
    </BackgroundContainer>
  );
};

export default FullBackgroundWithOverlay;
