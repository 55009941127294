import { useTheme } from "@emotion/react";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { handleGetQuote } from "../helpers/helperFunctions";

export default function Header(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        height: props?.type === 2  ? "60vh" :props?.type === 3 || props?.type === 4?"70vh": "100vh",
        width: "100%",
        overflowX: "hidden",
        backgroundImage: `
      linear-gradient(to bottom, transparent 50%, #00ADA0 100%), 
      url(${props?.backgroundImage})
    `,
        backgroundSize: "cover",
        backgroundPosition: props?.type == 3?"center 10%":"center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {/* <Container> */}
      {props?.type !== 2 && (
        <Box
          sx={{
            background:
              "linear-gradient(2.91deg, rgba(255, 255, 255, 0.4) 32.29%, rgba(153, 153, 153, 0) 70.43%)",
            height: 450,
            position: "absolute",
            bottom: props?.type === 1 ? 0 : '25vh',
            width: "100%",
            alignContent: "center",
          }}
        >
          <Box
            sx={{
              background:
                "radial-gradient(ellipse at 50% 50%,rgba(255, 255, 255, 0.6) 32.29%, rgba(153, 153, 153, 0) 70.43%)",
              height: 400,
              alignContent: "center",
            }}
          >
            {props?.type === 1 ? (
              <Box className="fadeIn">
                <Typography variant="h2" fontWeight={900} textAlign="center">
                  Cleaning Kitsap,
                </Typography>
                <Typography variant="h3" textAlign="center" fontWeight={"300"}>
                  One Space at a Time!
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  mt={"32px"}
                  fontWeight={400}
                >
                  From offices to homes , our expert team ensures a spotless
                  environment,
                  <br />
                  leaving you with more time to enjoy life.
                </Typography>
              </Box>
            ) : props?.type === 3 ? (
              <Box
              className="fadeIn"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap:'wrap',
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                {/* {!isMobile &&
                 <Box
                 component={"img"}
                 src={props?.icon}
                 sx={{
                   marginRight: "30px",
                 }}
               />
                } */}
                  <Typography
                    variant="h3"
                    fontWeight={900}
                    // lineHeight={"72px"}
                    letterSpacing={"1px"}
                    sx={{
                      marginLeft: !isMobile ? "0px" : "30px",
                    }}
                  >
                    {props?.titleText1}
                  </Typography>
                  {/* {!isMobile && ( */}
                    <Typography
                      variant="h3"
                      fontWeight={200}
                      // lineHeight={"72px"}
                      letterSpacing={"1px"}
                      ml={3}
                    >
                      {props?.titleText2}
                    </Typography>
                  {/* )} */}
                </Box>
                <Typography
                  variant="h6"
                  align="center"
                  mt={"32px"}
                  fontWeight={400}
                  padding={isMobile ? "0px 1em" : "0px 12em"}
                >
                  {props?.content}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                <Button
                  sx={{
                    padding: "8px 20px",
                    backgroundColor: "#00ADA0",
                    borderRadius: "80px",
                    marginTop: "32px",
                  }}
                  onClick={handleGetQuote}
                id="get-quote-menu-item"
                >
                  <Typography variant="body1" fontWeight={500} color="#FFFFFF"
                    sx={{
                      
                    }}
                  >
                    Get an Estimate now
                  </Typography>
                </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      )}
      {/* </Container> */}
    </Box>
  );
}
