let faqList = [
//   {
//     number: 1,
//     question: "What services do you offer?",
//     answer: "We offer a wide range of cleaning services, including:\n&#x2022; Commercial Cleaning\n&#x2022; Residential Cleaning\n&#x2022; Hospitality Cleaning\n&#x2022; Hotel Laundry Services\n&#x2022; Truckmount Steam Cleaning\n&#x2022; Hardfloor Services\n&#x2022; Move Out Cleaning\nWe also provide terminal surgery cleaning services for medical facilities and thorough housekeeping for special venues like Islandwood and Inn at Pleasant Beach.",
//   },
{
    number:2,
    question:"How long has Wonderland Cleaning been in business?",
    answer:'We’ve been providing quality cleaning services to communities and businesses in Kitsap for over 13 years.'
},
{
    number:3,
    question:"What areas do you serve?",
    answer:"We are proud to serve clients in the greater Kitsap area and surrounding regions. If you're unsure if you're within our service area, feel free to contact us."
},
// {
//     number:4,
//     question:"How do I request a quote?",
//     answer:"You can easily request a quote by visiting the \"Get a Quote\" page on our website. Fill out the form, and we'll get back to you with a detailed estimate. You can also call us directly at 360-200-7191."
// },
{
    number:4,
    question:"Do you provide your own cleaning supplies?",
    answer:"Yes, we bring all the necessary cleaning supplies and equipment to every job. If you have specific preferences or products you’d like us to use, let us know, and we would be happy to work with you in order to accommodate your request."
},
{
    number:5,
    question:"Are your services eco-friendly?",
    answer:"We offer eco-friendly cleaning options using environmentally safe products. If you're interested in green cleaning solutions, let us know when scheduling your service."
},
{
    number:6,
    question:"How do you ensure the quality of your cleaning services?",
    answer:"We implement strong quality control measures at every job. Our team is trained to follow detailed checklists to ensure every aspect of your space is cleaned to our high standards. We also welcome feedback to continuously improve our services."
},
{
    number:7,
    question:"Are your employees insured and bonded?",
    answer:"Yes, all of our employees are fully bonded and insured for your peace of mind. We are committed to fairly compensating our staff and take pride in making sure they are well-trained and prepared for any and all cleaning tasks at hand."
},
{
    number:8,
    question:"Can you handle large-scale commercial cleaning projects?",
    answer:"Absolutely! Whether you need regular office cleaning, a one-time deep clean for a large facility, or are interested in our Steam cleaning or hard floor services, we have the experience and equipment to handle projects of all sizes."
},
{
    number:9,
    question:"Do you offer emergency or same-day cleaning services?",
    answer:"We understand that unexpected situations arise, and we always do our best to accommodate urgent cleaning needs. Please contact us directly, and we’ll do everything we can to fit you into our schedule- even if that means coming out the same day!"
},
{
    number:10,
    question:"What makes Wonderland Cleaning different from other cleaning companies?",
    answer:"We have a long history of serving high-profile clients and venues, such as our friends at Islandwood, Pleasant Beach Village, and the Silverdale Center for Surgery. Our team is dedicated to delivering personalized, high-quality cleaning services, and we give back 5% of our revenue to local charity to do our part in supporting the community we care so much about."
},
{
    number:11,
    question:"How do I reschedule or cancel a service?",
    answer:"We understand that things come up, and we’re happy to work together with you to find a new time. If you need to reschedule or cancel a cleaning appointment, please contact us at least 24 hours in advance to avoid any cancellation fees."
},
];

export { faqList };
