import { Box } from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Header from "../Components/Header";
import FeaturedServices from "../Components/services/FeaturedServices";
import Testimonials from "../Components/Testimonials";
import Footer from "../Components/Footer";
import BookToday from "../Components/services/BookToday";
import Contact from "../Components/Contact";

export default function CommercialServices() {
  return (
    <Box>
      <ResponsiveAppBar />
      <Header 
      type={3}
      backgroundImage="/images/commerical_hero.jpg"
      icon = "/images/icons/building.png"
      titleText1 = "Commercial"
      titleText2 = "Cleaning Services"
      content="From commercial cleaning for offices, medical facilities, schools and churches, we tailor every project to fit your needs, ensuring a clean, healthy, and welcoming space for all."
      />
      <FeaturedServices 
        type={'commercial'}
      />
      <BookToday
      content={" Elevate your workspace with pristine cleanliness. Let us keep your office spectless so you can focus on what matters most."}
      />
      <Testimonials/>
      <Contact/>
      <Footer/>
    </Box>
  );
}
