import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const TestimonialModal = ({ open, handleClose,selectedTestimonial }) => {

  return (
    <div>
      {/* Modal Component */}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="lg" // Set to large width
        fullWidth // Ensures modal takes up the full width allowed by maxWidth
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent
          sx={{
            paddingBottom:'50px'
          }}
        >
            <Box>
            <Box
            mt="32px"
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <Box component={"img"} src="/images/image.png" /> */}
            <Box
              sx={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#40C4AA",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                fontWeight={500}
                color="#FFFFFF"
                sx={{
                  textTransform: "uppercase",
                  textAlign: "center",
                  lineHeight: "40px",
                }}
              >{selectedTestimonial?.firstLetter}</Typography>
            </Box>
            <Box ml="16px" sx={{ display: "flex", alignItems:'center' }}>
              <Typography variant="body1" fontWeight={400} color="#191919">
                {selectedTestimonial?.name}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop:'20px'
            }}
          >
            <Box component={"img"} src="/images/icons/star.png" />
            <Typography variant="h6" fontWeight={500} color="#191919" ml="8px" >
              {selectedTestimonial?.rating}
            </Typography>
          </Box>
          <Typography variant="h6" fontWeight={500} color="#191919" mt="8px">
            {selectedTestimonial?.review}
          </Typography>
            </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TestimonialModal;
