import React, { useState } from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import FeaturedServicesModal from "./FeaturedServicesModal";
import {
  commercialFeaturedImages,
  featuredImages,
  ResidentialFeaturedImages,
} from "../../Data/FeaturedServices";
import theme from "../../theme";
// import ScrollAnimation from "react-animate-on-scroll";
// import "animate.css/animate.compat.css";
import "../../styles/FeaturedServicesStyles.css"

const Overlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.4)", // Dark overlay with opacity
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#FFFFFF", // White text color for contrast
  opacity: 1, // Visible by default
}));

const TitleOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  padding: "16px 32px",
  // height: "10%",
  backgroundColor: "rgba(0, 0, 0, 0.6)", // Dark overlay with opacity
  display: "flex",
  // justifyContent: "center",
  alignItems: "center",
  color: "#FFFFFF", // White text color for contrast
  opacity: 1, // Visible by default
}));

const ContentOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "100%",
  //   height: "60%",
  backgroundColor: "#00000066", // White semi-transparent overlay
  backdropFilter: "blur(4px)", // Frosted glass effect
  display: "flex",
  flexDirection: "column",
  color: "#FFFFFF", // White text color
  opacity: 0, // Initially invisible
  transition: "opacity 0.3s ease", // Smooth transition on hover
}));

const Image = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover", // Ensure the image covers the container
});

const FeaturedServices = (props) => {
  const [open, setOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);

  const ImageContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    minWidth: props?.type == "commercial" || isMobile || isTablet? "300px" :'0px',
    maxWidth: "300px", // Adjust the minimum width of each image
    height: "480px", // Adjust the height as needed
    overflow: "hidden",
    borderRadius: "16px",
    marginRight: "16px", // Space between images
  }));

  const handleOpen = (index, title) => {
    setOpen(true);
    setActiveSection(
      props?.type == "commercial"
        ? index
        : props?.type == "residential" && title == "Property Management"
        ? 3
        : index + 5
    );
  };
  const handleClose = () => setOpen(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const renderImage = (item, index) => {
    return (
      <ImageContainer
        onMouseEnter={(e) => {
          e.currentTarget.querySelector(".contentOverlay").style.opacity = 1;
          e.currentTarget.querySelector(".titleOverlay").style.opacity = 0;
        }}
        onMouseLeave={(e) => {
          e.currentTarget.querySelector(".contentOverlay").style.opacity = 0;
          e.currentTarget.querySelector(".titleOverlay").style.opacity = 1;
        }}
      >
        <Image src={item?.image} alt="img" />
        <Overlay className="overlay" />
        <TitleOverlay className="titleOverlay">
          <Typography
            variant="body1"
            color="#FFFFFF"
            fontWeight={500}
            lineHeight={"28px"}
          >
            {item?.title}
          </Typography>
        </TitleOverlay>
        <ContentOverlay className="contentOverlay">
          <Box
            sx={{
              margin: "32px",
            }}
          >
            <Typography
              variant="h6"
              color="#FFFFFF"
              fontWeight={500}
              lineHeight={"28px"}
            >
              {item?.title}
            </Typography>
            <Typography
              variant="body2"
              color="#FFFFFF"
              fontWeight={400}
              lineHeight={"24px"}
              mt={1}
            >
              {item?.content}
            </Typography>
            {!isMobile && (
              <Box
                mt={"26px"}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleOpen(index, item?.title);
                }}
              >
                <Typography
                  variant="body2"
                  color="#FFFFFF"
                  fontWeight={400}
                  lineHeight={"28px"}
                >
                  More Details
                </Typography>
                <Box
                  ml={"16px"}
                  component={"img"}
                  src="/images/icons/rightArrowLong.png"
                />
              </Box>
            )}
          </Box>
        </ContentOverlay>
      </ImageContainer>
    );
  };

  return (
    <Box
      pt={"80px"}
      pb={"80px"}
      sx={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0px 10%",
          animationDelay: `0s`,
        }}
        className="fadeIn"
      >
        <Typography
          variant="h5"
          lineHeight={"28px"}
          fontWeight={500}
          color="#00262E"
        >
          Featured Services
        </Typography>
        <Button
          sx={{
            borderColor: "#DDDDDD",
            borderRadius: "32px",
            borderWidth: 1,
            borderStyle: "solid",
            padding: "10px 24px",
            color: "#00ADA0",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "28px",
          }}
          onClick={() => {
            setOpen(true);
            setActiveSection(0);
          }}
        >
          view all
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap", // Prevents wrapping, forces horizontal layout
          overflowX: "scroll", // Enables horizontal scroll
          padding: "64px 0px",
          scrollbarColor: "#00ADA0 #f0f0f0", // Scrollbar color for Firefox
          "&::-webkit-scrollbar": {
            height: "8px", // Set the height of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#00ADA0", // Custom scrollbar color
            borderRadius: "20px", // Rounded scrollbar edges
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0", // Scrollbar track background color
          },
          scrollbarWidth: "thin", // Thin scrollbar for Firefox,
          marginRight: props?.type == "commercial" ?'0px': "10%",
        }}
      >
        <Box sx={{ minWidth: "10%" }} />
        {props?.type == "commercial"
          ? commercialFeaturedImages?.map((item, index) => {
              return (
                <Box
                className="fadeIn"
                sx={{
                  animationDelay: `${index * 0.1}s`,
                }}
            >
                {renderImage(item, index)}
                </Box>
              )
            })
          : ResidentialFeaturedImages?.map((item, index) => {
              return (
                <Box
                className="fadeIn"
                sx={{
                  animationDelay: `${index * 0.1}s`,
                }}
            >
                {renderImage(item, index)}
                </Box>
                )
            })}
      </Box>
      {open && (
          <FeaturedServicesModal
            open={open}
            activeContainer={activeSection}
            handleClose={handleClose}
          />
        )}
    </Box>
  );
};

export default FeaturedServices;
