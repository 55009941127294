import { Box } from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Section1 from "../Components/about/Section1";
import Section2 from "../Components/about/Section2";
import AddressSection from "../Components/about/AddressSection";
import Contact from "../Components/Contact";
import GiveBack from "../Components/GiveBack";
import Footer from "../Components/Footer";
import OurValues from "../Components/about/OurValues";
import Header from "../Components/Header";

export default function About() {
  return (
    <Box>
      <ResponsiveAppBar />
    <Header
    type={2}
    backgroundImage='/images/aboutHeader.png'
    />
    <Section1/>
    {/* <OurValues/> */}
    {/* <Section2/> */}
    <AddressSection/>
    <Contact/>
    <Footer/>
    </Box>
  );
}
