import {
  Box,
  Container,
  Grid2,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

export default function GiveBack() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      sx={{
        backgroundColor: "#FFFFFF",
      }}
      pt={"80px"}
      pb={"80px"}
    >
      <Container>
        <Box
          sx={{
            width: isMobile?"100%":"70%",
          }}
        >
                                                  <ScrollAnimation animateIn="fadeInUp" delay={0} animateOnce duration={1.5}>
          <Typography variant="h5" fontWeight={400} color="#00262E">
            We Love To Give Back
          </Typography>
          <Typography variant="h6" fontWeight={400} color="#191919" mt={"21px"}>
            We proudly supports our community through donations to we grow
            collective and by offering charity cleaning services to those in
            need, We’re committed to making a positive impact beyound out
            business.
          </Typography>
          </ScrollAnimation>
        </Box>
        <Box mt={"57px"}>
          <Grid2 container spacing={2}>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
            >
                                        <ScrollAnimation animateIn="fadeInUp" delay={100} animateOnce duration={1.5}>
              <Box
                component={"img"}
                src="/images/charity/1.jpg"
                sx={{
                  width: "100%",
                  height: "190px",
                  objectFit: "cover",

                }}
              />
              </ScrollAnimation>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
            >
                                        <ScrollAnimation animateIn="fadeInUp" delay={200} animateOnce duration={1.5}>
              <Box
                component={"img"}
                src="/images/charity/2.jpg"
                sx={{
                  width: "100%",
                  height: "280px",
                }}
              />
              </ScrollAnimation>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
            >
                                        <ScrollAnimation animateIn="fadeInUp" delay={300} animateOnce duration={1.5}>
              <Box
                component={"img"}
                src="/images/charity/3.jpg"
                sx={{
                  width: "100%",
                  height: "370px",
                  objectFit: "cover",
                }}
              />
              </ScrollAnimation>
            </Grid2>
            <Grid2
              size={{
                xs: 12,
                sm: 12,
                md: 3,
              }}
              sx={{
                position: "relative",
              }}
            >
                          <ScrollAnimation animateIn="fadeInUp" delay={1200} animateOnce duration={1.5}>
              {!isMobile && (
                <Box
                  component={"img"}
                  src="/images/illustrations/gbIllustration.png"
                  sx={{
                    position: "absolute",
                    top: "-160px",
                    width: "100%",
                  }}
                />
              )}
              </ScrollAnimation>
                          <ScrollAnimation animateIn="fadeInUp" delay={400} animateOnce duration={1.5}>
              <Box
                component={"img"}
                src="/images/charity/4.jpg"
                sx={{
                  width: "100%",
                  height: "220px",
                }}
              />
              </ScrollAnimation>
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </Box>
  );
}
