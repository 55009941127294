import { Box, Container, Grid2, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { locationsList } from "../../Data/Locations";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";

export default function AddressSection() {
  const [selectedLocation,setSelectedLocation] = useState(locationsList['Kingston']);
  const mapSectionRef = useRef(null);

const handleOnPressViewMap = (title) => {
  setSelectedLocation(locationsList[title]);
  window.scrollTo({
    top: mapSectionRef.current.offsetTop -130, // Get the offset top position of the section
    behavior: 'smooth', // Enable smooth scrolling
  });

}

  const renderAddress = (title, content,index) => {
    return (
      <ScrollAnimation animateIn="fadeInUp" delay={index*100} animateOnce duration={1.5}>
      <Box>
        <Typography variant="h6" color="#191919" fontWeight={600}>
          {title}
        </Typography>
        <Typography variant="body2" color="#191919" fontWeight={400}>
          {content}
        </Typography>
        <Typography
          variant="body1"
          fontSize={"16px"}
          lineHeight={"28px"}
          color="#191919"
          fontWeight={400}
          mt={"16px"}
          sx={{
            cursor:'pointer'
          }}
          onClick = {() => {
            handleOnPressViewMap(title)
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            View Map
            <Box
              component={"img"}
              src="/images/icons/rightArrowLong.png"
              ml={2}
            />
          </Box>
        </Typography>
      </Box>
      </ScrollAnimation>
    );
  };

  return (
    <Box
      pt={"70px"}
      pb={"70px"}
      sx={{
        backgroundColor: "#EEF7F6",
      }}
    >
      <Container>
        <Typography
          variant="h5"
          fontWeight={400}
          lineHeight={"35px"}
          color="#00262E"
        >
          We offer our services here
        </Typography>
        <Grid2 container spacing={2} mt={"35px"}>
          {Object?.keys(locationsList)?.map((item,index) => (
            <Grid2
              size={{
                xs: 6,
                sm: 6,
                md: 3,
              }}
            >
              {renderAddress(item, "Washington",index)}
            </Grid2>
          ))}
        </Grid2>
        <Box mt={"80px"} ref={mapSectionRef}>
          <iframe
            src={selectedLocation}
            width="100%"
            height="400"
            style={{
              border: 0,
              borderRadius: "14px",
              filter: "grayscale(100%) invert(92%) contrast(83%)",
            }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            title="map"
          />
          {/* <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13331.108472064965!2d151.197770220603!3d-33.86902795219149!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12ae3c36fc2c21%3A0xbf6ac40e233d3415!2sQueen%20Victoria%20Building!5e1!3m2!1sen!2sin!4v1727588849958!5m2!1sen!2sin"
            width="100%"
            height="600px"
            style={{ border: 0,borderRadius:'14px' }}
            allowfullscreen=""
            loading="lazy"
            referrerPolicy="no-referrer"
            title="map"
          />{" "} */}
        </Box>
      </Container>
    </Box>
  );
}
