import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Typography,
} from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import HorizontalNonLinearStepper from "../Components/reusable/HorizontalLinearStepper";

export default function GetQuote() {
  return (
    <Box>
      <ResponsiveAppBar />
      <Container>
        <p
          style={{
            fontSize: "56px",
            fontWeight: "200",
            fontFamily: "DM Sans, sans-serif",
            marginTop: "10%",
            textAlign: "center",
            marginBottom: "8px",
          }}
        >
          Get an{" "}
          <span
            style={{
              fontWeight: "700",
            }}
          >
            Estimate
          </span>
        </p>
        <Typography variant="body1" fontWeight={400} textAlign="center">
          Need a one-time deep clean, regular janitorial service, or a custom
          cleaning solution? Fill out the form below for a tailored quote!
        </Typography>
        <Card
          sx={{
            mt: "60px",
          }}
        >
          <CardContent
            sx={{
              margin: 0,
              padding: 0,
            }}
          >
            <Box
              sx={{
                padding: "5px 24px",
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'space-between',
              }}
            >
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  fontFamily: "DM Sans, sans-serif",
                }}
              >
                Choose your service type
              </p>
              <Box>
                <HorizontalNonLinearStepper/>
              </Box>
            </Box>
            <Divider />
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}
