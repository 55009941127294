import { Box } from "@mui/material";
import React from "react";
import ResponsiveAppBar from "../Components/ResponsiveAppBar";
import Header from "../Components/Header";
import FeaturedServices from "../Components/services/FeaturedServices";
import Testimonials from "../Components/Testimonials";
import Footer from "../Components/Footer";
import BookToday from "../Components/services/BookToday";
import Contact from "../Components/Contact";

export default function ResidentialServices() {
  return (
    <Box>
      <ResponsiveAppBar />
      <Header 
      type={3}
       backgroundImage="/images/residential_hero.jpg"
       icon = "/images/icons/house.png"
       titleText1 = "Residential"
       titleText2 = "Cleaning Services"
       content="Top-tier residential cleaning for houses, apartments, and move-ins/outs. We use safe, effective products to keep your home healthy and comfortable."
       />
      <FeaturedServices
                type={'residential'}
      />
      <BookToday
      content={"Trust our reliable team for a high-quality clean that leaves your home spotless, giving you more time for what you love."}
      />
      <Testimonials/>
      <Contact/>
      <Footer/>
    </Box>
  );
}
