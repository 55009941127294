import React, { useState, useRef } from 'react';
import { Box, IconButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const carouselData = [
  { id: 1, content: 'Slide 1 Content' },
  { id: 2, content: 'Slide 2 Content' },
  { id: 3, content: 'Slide 3 Content' },
];

const CustomCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef(null);
  const touchStartX = useRef(0);

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === carouselData.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? carouselData.length - 1 : prevIndex - 1
    );
  };

  // Handle swipe gestures
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    if (touchStartX.current - touchEndX > 50) {
      handleNext();
    } else if (touchEndX - touchStartX.current > 50) {
      handlePrev();
    }
  };

  return (
    <Box
      ref={carouselRef}
      sx={{
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        maxWidth: '400px', // adjust for mobile size
        height: '200px', // carousel height
        mx: 'auto',
        mt: 4,
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {/* Slide Container */}
      <Box
        sx={{
          display: 'flex',
          transform: `translateX(-${activeIndex * 100}%)`,
          transition: 'transform 0.5s ease-in-out',
          width: `${carouselData.length * 100}%`,
        }}
      >
        {carouselData.map((slide) => (
          <Box
            key={slide.id}
            sx={{
              minWidth: '100%',
              height: '100%',
              display: 'flex',
              backgroundColor: 'lightgrey',
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            {slide.content}
          </Box>
        ))}
      </Box>

      {/* Navigation Buttons */}
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'white',
        }}
        onClick={handlePrev}
      >
        <ArrowBackIosNewIcon />
      </IconButton>
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          backgroundColor: 'white',
        }}
        onClick={handleNext}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </Box>
  );
};

export default CustomCarousel;
