const commercialFeaturedImages = [
    {
      image: "/images/commerical_hero.jpg",
      title: "Commercial Janitorial",
      content:
        "Unmatched janitorial services that keep your business flawlessly spotless.",
      fullcontent:"Our professional and dependable janitorial services are tailored to meet the specific needs of your business, ensuring a spotless, hygienic, and welcoming environment for both employees and customers. Our team follows a detailed checklist that covers all high-touch areas, maintaining a consistently clean and sanitized workspace. With a focus on reliability and quality, we help reinforce the high standards of your business, so you can focus on serving your customers."
    },
    {
      image: "/images/commercial/5.png",
      title: "Hotel & Continuing Care",
      content:
        "Exceptional cleaning and laundry services for a comfortable guest experience.",
      fullcontent:"Tailored for hotels, resorts, and continuing care facilities, our cleaning and laundry services ensure every guest experiences a pristine and comfortable stay. We operate with high efficiency to minimize disruption, adhering to strict hygiene protocols for every room, common area, and on-site laundry task. From spotless guest rooms to meticulously maintained public areas, our team supports your commitment to quality, elevating guest satisfaction and peace of mind."
    },
    {
      image: "/images/commercial/steamCleaning.jpg",
      title: "Steam Cleaning",
      content:
        "Powerful steam cleaning that removes allergens, renews color, reduces smell, and revitalizes carpets.",
      fullcontent:"Experience the renewal of your carpets and upholstery with our deep steam cleaning services, which reach beyond the surface to eliminate dirt, allergens, and odors. Our advanced steam-cleaning technology refreshes colors, restores fibers, and leaves your space feeling fresh and healthy. Committed to quality and precision, our process enhances both the cleanliness and comfort of your home or business."
    },
    {
      image: "/images/Residential/2.png",
      title: "Property Management",
      content:
        "Premier cleaning that maximizes the appeal and value of properties.",
      fullcontent:"From multi-family residences to single-family homes and commercial properties, our expert cleaning services enhance the appeal and overall value of your properties. We work closely with property managers to customize cleaning schedules and processes that meet your specific needs, leaving each space polished and ready to impress tenants and potential buyers alike. With a reputation for thoroughness and reliability, we help you create inviting and well-maintained environments."
    },
    {
      image: "/images/commercial/hardFloor.png",
      title: "Hard Floor Service",
      content:
        "Top tier stripping, waxing, and buffing services for stunning, flawless floors.",
      fullcontent:"Specialized in hard floor care, our strip, wax, and buff services are designed to bring out the natural beauty of your floors while extending their lifespan. Using professional-grade products and techniques, our team meticulously removes old wax, applies a new, durable finish, and buffs it to a high gloss. With attention to detail at every step, we leave your floors looking clean, polished, and protected from daily wear."
    },
  ];

  const ResidentialFeaturedImages = [
    {
      image: "/images/Residential/1.png",
      title: "Residential Recurring",
      content:
        "Impeccable weekly cleaning that keeps your home clean and pristine.",
      fullcontent:"Enjoy a consistently clean and inviting home with our recurring residential cleaning services. Our team develops a personalized cleaning plan tailored to your preferences, so each visit maintains the high standard of cleanliness you expect. With reliable scheduling and a meticulous approach, we make it easy for you to enjoy a fresh and spotless home week after week, giving you more time to focus on what matters most."
    },
    {
      image: "/images/Residential/3.png",
      title: "Move Out Cleaning",
      content:
        "Unbeatable move-out cleaning that leaves your space immaculately spotless.",
      fullcontent:"Our thorough move-out cleaning services ensure your property is left in pristine condition, making it ready for new occupants. We cover every corner, from scrubbing floors and baseboards to deep cleaning appliances, creating a fresh start for the next tenant. Whether you’re a homeowner, renter, or property manager, you can trust us to handle the details, helping you secure deposits and create lasting impressions."
    },
    {
      image: "/images/Residential/6.png",
      title: "New Construction Cleaning",
      content:
        "Flawless post-construction cleaning, preparing your new build for perfection.",
      fullcontent:"Our meticulous post-construction cleaning services prepare your new build for move-in, ensuring every surface is spotless and free of dust and debris. We handle all aspects of construction cleanup, including removing adhesives, polishing fixtures, and detailing floors and windows. With a team trained to uphold rigorous standards, we help you present a flawless final product that’s ready to welcome new occupants."
    },
    {
      image: "/images/Residential/2.png",
      title: "Property Management",
      content:
        "Premier cleaning that maximizes the appeal and value of properties.",
      fullcontent:"From multi-family residences to single-family homes and commercial properties, our expert cleaning services enhance the appeal and overall value of your properties. We work closely with property managers to customize cleaning schedules and processes that meet your specific needs, leaving each space polished and ready to impress tenants and potential buyers alike. With a reputation for thoroughness and reliability, we help you create inviting and well-maintained environments."
    },
  ]

  const featuredImages = [...commercialFeaturedImages, ...ResidentialFeaturedImages].slice(0, 8);
  console.log(featuredImages)

  export { commercialFeaturedImages, ResidentialFeaturedImages,featuredImages };