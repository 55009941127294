import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Grid2,
  Typography,
} from "@mui/material";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.compat.css";
import "../../styles/FeaturedServicesStyles.css"

export default function Section1() {
  const renderValuesCard = (color, icon, title, content) => {
    return (
      <Card
        sx={{
          boxShadow: 0,
        }}
      >
        <CardContent
          sx={{
            padding: 0,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Horizontally center
              alignItems: "center", // Vertically center
              height: "200px", // Set height for the container
              width: "100%", // Set width to 100% or any other value
              backgroundColor: color, // Optional: background color for the div
              borderRadius: "16px",
            }}
          >
            <Box
              component={"img"}
              src={icon}
              sx={{
                maxWidth: "100%",
                maxHeight: "100%", // Ensure the image fits within the container
                objectFit: "contain", // Prevent image distortion
              }}
            />
          </Box>
          <Box mt={"12px"}>
            <Typography
              variant="h5"
              fontWeight={400}
              color="#0D0D12"
              lineHeight={"35px"}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              color="#000000"
              fontWeight={400}
              lineHeight={"24px"}
              mt={"12px"}
            >
              {content}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );
  };
  return (
    <Box
      pt={"80px"}
      pb={"80px"}
      sx={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <Container>
        <Typography
          variant="h4"
          fontWeight={500}
          color="#00ADA0"
          lineHeight={"63px"}
          className="fadeIn"
        >
          Reliable. Affordable. Impeccable.
        </Typography>
        <Typography
          variant="h6"
          fontWeight={400}
          color="#00262E"
          lineHeight={"26px"}
          mt={1}
          className="fadeIn"
        >
          For over 13 years, Wonderland Cleaning has been dedicated to keeping
          Kitsap County spotless. As a locally owned business, we believe in
          giving back to our community, contributing a portion of our revenue to
          charity. We proudly serve esteemed clients like Islandwood, Pleasant
          Beach Village, Silverdale Center for Surgery, and Silverdale Dental
          Center, offering unparalleled reliability, affordability, and
          professionalism. At Wonderland Cleaning, we maintain strong
          communication with our clients, ensuring that every service is
          tailored to meet your needs. Let us help you achieve a cleaner,
          healthier environment today.
        </Typography>
        <ScrollAnimation
              animateIn="fadeInUp"
              delay={100}
              animateOnce
              duration={1.5}
            >
        <Box mt={10}>
          <Typography
            variant="h5"
            fontWeight={500}
            color="#00262E"
            lineHeight={"28px"}
          >
            Our Values
          </Typography>
          <Typography
            variant="h6"
            fontWeight={400}
            color="#00262E"
            lineHeight={"28px"}
            mt={"12px"}
          >
            Our core values guide everything we do, ensuring that quality,
            integrity and customer focus are at the heart of our cleaning
            services.
          </Typography>
        </Box>
        </ScrollAnimation>
        <Grid2 container spacing={4} mt={"35px"}>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 4,
            }}
          >
            <ScrollAnimation
              animateIn="fadeInUp"
              delay={100}
              animateOnce
              duration={1.5}
            >
              {renderValuesCard(
                "#33CFFF",
                "/images/icons/checkCircle.png",
                "Quality",
                "We strive for the highest standards in every service we offer. Our attention to detail ensures that every corner is thoroughly cleaned."
              )}
            </ScrollAnimation>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 4,
            }}
          >
            <ScrollAnimation
              animateIn="fadeInUp"
              delay={200}
              animateOnce
              duration={1.5}
            >
              {renderValuesCard(
                "#40C4AA",
                "/images/icons/shield.png",
                "Integrity",
                "We operate with honesty and transparency, building trust with our clients through consistent, reliable service."
              )}
            </ScrollAnimation>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              sm: 12,
              md: 4,
            }}
          >
            <ScrollAnimation
              animateIn="fadeInUp"
              delay={300}
              animateOnce
              duration={1.5}
            >
              {renderValuesCard(
                "#FFBE4C",
                "/images/icons/users.png",
                "Customer Centric",
                "Your satisfaction is our top priority. We customize our services to fit your specific needs and preferences."
              )}
            </ScrollAnimation>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}
