let locationsList = {
  Kingston:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21443.910946478754!2d-122.50638049999999!3d47.791358450000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54901913bcd2309f%3A0xad4d9421eb7fd2be!2sKingston%2C%20WA%2098346%2C%20USA!5e0!3m2!1sen!2sin!4v1728385400469!5m2!1sen!2sin",
  Poulsbo:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42925.12015905119!2d-122.64259045000001!3d47.7461469!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490224d2c266583%3A0x3e18de5222d7b049!2sPoulsbo%2C%20WA%2098370%2C%20USA!5e0!3m2!1sen!2sin!4v1728385552181!5m2!1sen!2sin",
  BainbridgeIsland:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d111746.3297101303!2d-122.51663559386438!3d47.64351817606251!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54903c3256128b2b%3A0xdfa86644a0f0930e!2sBainbridge%20Island%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1728385745860!5m2!1sen!2sin",
  Suquamish:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d72093.56710184913!2d-122.57150703319887!3d47.70496593068017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549022d4410d2fc1%3A0x72449f1539869c0!2sSuquamish%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1728385785637!5m2!1sen!2sin",
  Silverdale:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d80711.85827818591!2d-122.68514444343263!3d47.663661138620675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549025035b5855f3%3A0x83a62a573d28d5f8!2sSilverdale%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1728385828953!5m2!1sen!2sin",
  Bremerton:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d180157.44891046823!2d-122.7249226577773!3d47.54360764469715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x549037795212e35b%3A0xb238651d502a0952!2sBremerton%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1728385867339!5m2!1sen!2sin",
  Keyport:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21597.704330384375!2d-122.62650398868315!3d47.69764134255505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54902354becf9023%3A0x212398a49f16f3d8!2sKeyport%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1728385905753!5m2!1sen!2sin",
  PortOrchard:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d43111.83070291439!2d-122.66179445000002!3d47.5193339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490362b813efd01%3A0x7b3b46c5baa649cc!2sPort%20Orchard%2C%20WA%2C%20USA!5e0!3m2!1sen!2sin!4v1728385940613!5m2!1sen!2sin",
  Seabeck:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63354.17912132787!2d-122.82502685643716!3d47.643960672728426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54902e18fd5683bb%3A0xd1d0511cb013ef1b!2sSeabeck%2C%20WA%2098380%2C%20USA!5e0!3m2!1sen!2sin!4v1728385989998!5m2!1sen!2sin",
};

export {locationsList}
